import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { service_paths, fallback_route, auth_route } from "./path";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store/reducers";
import { TokenStateStatus } from "src/redux/store/reducers/token";
import ScreenURL from "./route_list";
import BaseGrid from "@utils/templates/BaseGrid";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        {auth_route.map((route, id) => (
          <Route
            key={`AuthRoute${id}`}
            path={route.path}
            element={<AuthRoute component={route.element} />}
          />
        ))}
        {service_paths.map((route, id) => (
          <Route
            key={`ServiceRoute${id}`}
            path={route.path}
            element={<ProtectedRoute component={route.element} />}
          />
        ))}
        {fallback_route.map((route, id) => (
          <Route
            key={`FallbackRoute${id}`}
            path={route.path}
            element={<CommonRoute component={route.element} />}
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

const AuthRoute: React.FC<{
  component: React.LazyExoticComponent<any>;
}> = ({ component: Component }) => {
  const tokenState = useSelector((state: RootState) => state.token).state;
  const navigate = useNavigate();

  useEffect(() => {
    if (tokenState.status === TokenStateStatus.SUCCESS)
      navigate(ScreenURL.MANAGE_CLIENT, { replace: true });
  }, []);

  return (
    <React.Suspense fallback={<></>}>
      <Component />
    </React.Suspense>
  );
};

const ProtectedRoute: React.FC<{
  component: React.LazyExoticComponent<any>;
}> = ({ component: Component }) => {
  const tokenState = useSelector((state: RootState) => state.token).state;
  const navigate = useNavigate();

  useEffect(() => {
    if (tokenState.status !== TokenStateStatus.SUCCESS)
      navigate(ScreenURL.LOGIN, { replace: true });
  }, []);

  if (tokenState.status !== TokenStateStatus.SUCCESS) return <></>;

  return (
    <React.Suspense fallback={<BaseGrid children={<></>} />}>
      <Component />
    </React.Suspense>
  );
};

const CommonRoute: React.FC<{
  component: React.LazyExoticComponent<any>;
}> = ({ component: Component }) => {
  return (
    <React.Suspense fallback={<></>}>
      <Component />
    </React.Suspense>
  );
};
