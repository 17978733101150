import React from "react";
import { useSelector } from "react-redux";
import loadable from "@loadable/component";
import { RootState } from "src/redux/store/reducers";
import { ModalInfo, ModalType } from "src/redux/store/reducers/modal";

const ClientInfoModal = loadable(() => import("src/modals/ClientInfoModal"));

const SendEmailModal = loadable(() => import("src/modals/SendEmailModal"));

const UpdateOwnerModal = loadable(() => import("src/modals/UpdateOwnerModal"));

const DeleteClientModal = loadable(
  () => import("src/modals/DeleteClientModal")
);

const DeleteWorkspaceModal = loadable(
  () => import("src/modals/DeleteWorkspaceModal")
);

const DeleteWorkspaceClientModal = loadable(
  () => import("src/modals/DeleteWorkspaceMemberModal")
);

const ManageClientModal = loadable(
  () => import("src/modals/ManageClientModal")
);

const BulkRenewWorkspacePlanModal = loadable(
  () => import("src/modals/BulkRenewWorkspacePlanModal")
);

const BulkUpdateWorkspaceMemberModal = loadable(
  () => import("src/modals/BulkUpdateWorkspaceMemberModal")
);

const SetMemberPermissionsModal = loadable(
  () => import("src/modals/SetMemberPermissionsModal")
);

const DeleteOwnerWarningModal = loadable(
  () => import("src/modals/DeleteOwnerWarningModal")
);

const DeleteOrganizationModal = loadable(
  () => import("src/modals/DeleteOrganizationModal")
);

const ManageOrganizationAdminModal = loadable(
  () => import("src/modals/ManageOrganizationAdminModal")
);

const ManageOrganizationWorkspaceModal = loadable(
  () => import("src/modals/ManageOrganizationWorkspaceModal")
);

const DeleteOrganizationAdminModal = loadable(
  () => import("src/modals/DeleteOrganizationAdminModal")
);

const DeleteWorkspaceMemberAlertModal = loadable(
  () => import("src/modals/DeleteWorkspaceMemberAlertModal")
);

const ReconfirmModal = loadable(() => import("src/modals/ReconfirmModal"));

const BulkUpdateHistoryModal = loadable(
  () => import("src/modals/BulkUpdateHistoryModal")
);

const SetDownloadDateModal = loadable(
  () => import("src/modals/SetDownloadDateModal")
);

const UpdateWorkspaceInfoModal = loadable(
  () => import("src/modals/UpdateWorkspaceInfoModal")
);

const OrganizationAdminConfigModal = loadable(
  () => import("src/modals/OrganizationAdminConfigModal")
);

const ConfigAutomatedRenewNotifyMailModal = loadable(
  () => import("src/modals/ConfigAutomatedRenewNotifyMailModal")
);

export default function GlobalModalManager() {
  const modalState = useSelector((state: RootState) => state.modal.state);

  const renderModalContent = (modalInfo: ModalInfo) => {
    switch (modalInfo.type) {
      case ModalType.ORGANIZATION_ADMIN_CONFIG:
        return <OrganizationAdminConfigModal {...modalInfo} />;

      case ModalType.UPDATE_WORKSPACE_INFO:
        return <UpdateWorkspaceInfoModal {...modalInfo} />;

      case ModalType.CLIENT_INFO:
        return <ClientInfoModal {...modalInfo} />;

      case ModalType.SEND_EMAIL:
        return <SendEmailModal {...modalInfo} />;

      case ModalType.UPDATE_OWNER:
        return <UpdateOwnerModal {...modalInfo} />;

      case ModalType.DELETE_CLIENT:
        return <DeleteClientModal {...modalInfo} />;

      case ModalType.DELETE_WORKSPACE:
        return <DeleteWorkspaceModal {...modalInfo} />;

      case ModalType.DELETE_WORKSPACE_MEMBER:
        return <DeleteWorkspaceClientModal {...modalInfo} />;

      case ModalType.MANAGE_CLIENT:
        return <ManageClientModal {...modalInfo} />;

      case ModalType.BULK_UPDATE_WORKSPACE_PLAN:
        return <BulkRenewWorkspacePlanModal {...modalInfo} />;

      case ModalType.BULK_UPDATE_WORKSPACES_MEMBER:
        return <BulkUpdateWorkspaceMemberModal {...modalInfo} />;

      case ModalType.SET_MEMBER_PERMISSIONS:
        return <SetMemberPermissionsModal {...modalInfo} />;

      case ModalType.DELETE_OWNER_WARNING:
        return <DeleteOwnerWarningModal {...modalInfo} />;

      case ModalType.DELETE_ORGANIZATION:
        return <DeleteOrganizationModal {...modalInfo} />;

      case ModalType.MANAGE_ORGANIZATION_ADMIN:
        return <ManageOrganizationAdminModal {...modalInfo} />;

      case ModalType.MANAGE_ORGANIZATION_WORKSPACE:
        return <ManageOrganizationWorkspaceModal {...modalInfo} />;

      case ModalType.DELETE_ORGANIZATION_ADMIN:
        return <DeleteOrganizationAdminModal {...modalInfo} />;

      case ModalType.DELETE_WORKSPACE_MEMBER_ALERT:
        return <DeleteWorkspaceMemberAlertModal {...modalInfo} />;

      case ModalType.RECONFIRM:
        return <ReconfirmModal {...modalInfo} />;

      case ModalType.BULK_UPDATE_HISTORY:
        return <BulkUpdateHistoryModal />;

      case ModalType.SET_DOWNLOAD_DATE:
        return <SetDownloadDateModal {...modalInfo} />;

      case ModalType.CONFIG_AUTOMATED_RENEW_NOTIFY_MAIL:
        return <ConfigAutomatedRenewNotifyMailModal {...modalInfo} />;
    }
  };

  return (
    <React.Fragment>
      {modalState.openedModalList.map((modalInfo) => (
        <React.Fragment key={modalInfo.type}>
          {renderModalContent(modalInfo)}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}
