import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  transition,
  mkReducer,
  StateMachineAction,
} from "@redwit-react-commons/reducers/state3";
import { ParsedTaskObject } from "@redwit-commons/object/task";
import {
  AdminOrganizationAdmin,
  AdminOrganizationSummary,
  AdminClient,
  AdminWorkspace,
} from "@goono-commons/v3/goonoAdmin";
import { WorkspaceSummaryDto } from "@utils/types/workspace";
import { AdminWorkspaceMemberWithLogData } from "@goono-commons/v3/goonoAdmin";
import { WorkspacePlanOptionType } from "@utils/types/selectOptions";
import { WorkspacesWithMembers } from "@utils/types/modal";
import {
  Workspace,
  WorkspacePlanServiceEnum,
} from "@goono-commons/v3/workspace";
import { GetWorkspaceListQueryParams } from "@react-query/workspace/useGetWorkspaceListQuery";

export enum ModalStateStatus {
  INIT = "Modal::INIT",
}

export enum ModalActionKind {
  TRY_OPEN = "ModalAction::TRY_OPEN",
  TRY_CLOSE = "ModalAction::TRY_CLOSE",
  TRY_CLOSE_ALL = "ModalAction::TRY_CLOSE_ALL",
}

export enum ModalType {
  ORGANIZATION_ADMIN_CONFIG = "ModalType::ORGANIZATION_ADMIN_CONFIG",
  UPDATE_WORKSPACE_INFO = "ModalType::UPDATE_WORKSPACE_INFO",
  MANAGE_CLIENT = "ModalType::MANAGE_CLIENT",
  CLIENT_INFO = "ModalType::CLIENT_INFO",
  SEND_EMAIL = "ModalType::SEND_EMAIL",
  DELETE_WORKSPACE = "ModalType::DELETE_WORKSPACE",
  DELETE_WORKSPACE_MEMBER = "ModalType::DELETE_WORKSPACE_MEMBER",
  DELETE_CLIENT = "ModalType::DELETE_CLIENT",
  UPDATE_OWNER = "ModalType::UPDATE_OWNER",
  BULK_UPDATE_WORKSPACE_PLAN = "ModalType::BULK_UPDATE_WORKSPACE_PLAN",
  BULK_UPDATE_WORKSPACES_MEMBER = "ModalType::BULK_UPDATE_WORKSPACES_MEMBER",
  SET_MEMBER_PERMISSIONS = "ModalType::SET_MEMBER_PERMISSIONS",
  DELETE_OWNER_WARNING = "ModalType::DELETE_OWNER_WARNING",
  DELETE_ORGANIZATION = "ModalType::DELETE_ORGANIZATION",
  MANAGE_ORGANIZATION_ADMIN = "ModalType::MANAGE_ORGANIZATION_ADMIN",
  MANAGE_ORGANIZATION_WORKSPACE = "ModalType::MANAGE_ORGANIZATION_WORKSPACE",
  DELETE_ORGANIZATION_ADMIN = "ModalType::DELETE_ORGANIZATION_ADMIN",
  DELETE_WORKSPACE_MEMBER_ALERT = "ModalType::DELETE_WORKSPACE_MEMBER_ALERT",
  RECONFIRM = "ModalType::RECONFIRM",
  BULK_UPDATE_HISTORY = "ModalType::BULK_UPDATE_HISTORY",
  SET_DOWNLOAD_DATE = "ModalType::SET_DOWNLOAD_DATE",
  CONFIG_AUTOMATED_RENEW_NOTIFY_MAIL = "ModalType::CONFIG_AUTOMATED_RENEW_NOTIFY_MAIL",
}

export type ModalError = never;

export type OrganizationAdminConfigModalProps = {
  props:
    | {
        type: "create";
      }
    | {
        type: "update";
        organizationAdmin: AdminOrganizationAdmin;
      };
};

export type UpdateWorkspaceInfoModalProps = {
  workspace: AdminWorkspace;
};

export type ManageClientModalProps = {
  readonly ownWorkspace?: AdminWorkspace;
  readonly workspaceSummary?: WorkspaceSummaryDto;
  readonly planLevel?: WorkspacePlanOptionType;
  readonly workspaceMembers?: AdminWorkspaceMemberWithLogData[];
  readonly onConfirm: (params: {
    workspaceId?: string;
    assignedMembers?: AdminWorkspaceMemberWithLogData[];
    resignedMembers?: AdminWorkspaceMemberWithLogData[];
  }) => void;
};

export type ClientInfoModalProps = {
  readonly id: string;
  readonly pendingClientList: ParsedTaskObject[];
};

export type SendEmailModalProps = {
  readonly clientInfo: AdminClient;
  readonly pendingClients: ParsedTaskObject[];
};

export type DeleteWorkspaceModalProps = {
  readonly targetWorkspace: AdminWorkspace;
};

export type DeleteWorkspaceMemberModalProps = {
  readonly targetWorkspace: AdminWorkspace;
  readonly targetMember: AdminWorkspaceMemberWithLogData;
};

export type DeleteClientModalProps = {
  readonly targetClient: AdminClient;
};

export type UpdateOwnerModalProps = {
  readonly targetClient: AdminClient;
  readonly service: WorkspacePlanServiceEnum;
};

export type BulkRenewWorkspacePlanModalProps = {
  readonly workspaceIdFilters: WorkspaceIdFilter;
  readonly workspaceListFilter: GetWorkspaceListQueryParams;
};

type WorkspaceIdFilter = {
  workspaceIds: string[];
  excludeIds: string[];
};

export type BulkUpdateWorkspacesMemberModalProps = {
  readonly workspaceIdFilters: WorkspaceIdFilter;
  readonly workspaceListFilter: GetWorkspaceListQueryParams;
  readonly workspaces: WorkspacesWithMembers;
};

export type SetMemberPermissionsModalProps = {
  readonly workspaceIdFilters: WorkspaceIdFilter;
  readonly workspaceListFilter: GetWorkspaceListQueryParams;
  readonly selectedMembers: AdminClient[];
};

export type DeleteOwnerWarningModalProps = {
  readonly confirm: () => void;
};

export type DeleteOrganizationModalProps = {
  readonly organization: AdminOrganizationSummary;
};

export type ManageOrganizationAdminModalProps = {
  readonly organizationAdmins?: AdminOrganizationAdmin[];
  readonly onConfirm: (newAdmins: AdminOrganizationAdmin[]) => void;
};

export type ManageOrganizationWorkspaceModalProps = {
  readonly organizationWorkspaces?: Workspace[];
  readonly onConfirm: (newWorkspaces: Workspace[]) => void;
};

export type DeleteOrganizationAdminModalProps = {
  readonly organizationAdmin: AdminOrganizationAdmin;
};

export type DeleteWorkspaceMemberAlertModalProps = {
  readonly callback: () => void;
};

export type ReconfirmModalProps = {
  readonly title: string;
  readonly bodyTitle?: string;
  readonly bodyMsg: string;
  readonly confirmMsg: string;
  readonly reconfirmInputOption?: {
    answer: string;
  };
  readonly onConfirm: (() => void) | (() => Promise<void>);
};

export type SetDownloadDateModalProps = {
  readonly title: string;
  readonly onConfirm: (params: { from: string; to: string }) => void;
};

export type ConfigAutomatedRenewNotifyMailModalProps = {
  readonly workspaceId: string;
  readonly workspaceName: string;
};

type GenericModalInfo<T = ModalType, U = any> = {
  readonly type: T;
} & U;

export type ModalInfo =
  | GenericModalInfo<
      ModalType.ORGANIZATION_ADMIN_CONFIG,
      OrganizationAdminConfigModalProps
    >
  | GenericModalInfo<
      ModalType.UPDATE_WORKSPACE_INFO,
      UpdateWorkspaceInfoModalProps
    >
  | GenericModalInfo<ModalType.MANAGE_CLIENT, ManageClientModalProps>
  | GenericModalInfo<ModalType.CLIENT_INFO, ClientInfoModalProps>
  | GenericModalInfo<ModalType.SEND_EMAIL, SendEmailModalProps>
  | GenericModalInfo<ModalType.DELETE_WORKSPACE, DeleteWorkspaceModalProps>
  | GenericModalInfo<
      ModalType.DELETE_WORKSPACE_MEMBER,
      DeleteWorkspaceMemberModalProps
    >
  | GenericModalInfo<ModalType.DELETE_CLIENT, DeleteClientModalProps>
  | GenericModalInfo<ModalType.UPDATE_OWNER, UpdateOwnerModalProps>
  | GenericModalInfo<
      ModalType.BULK_UPDATE_WORKSPACE_PLAN,
      BulkRenewWorkspacePlanModalProps
    >
  | GenericModalInfo<
      ModalType.BULK_UPDATE_WORKSPACES_MEMBER,
      BulkUpdateWorkspacesMemberModalProps
    >
  | GenericModalInfo<
      ModalType.SET_MEMBER_PERMISSIONS,
      SetMemberPermissionsModalProps
    >
  | GenericModalInfo<
      ModalType.DELETE_OWNER_WARNING,
      DeleteOwnerWarningModalProps
    >
  | GenericModalInfo<
      ModalType.DELETE_ORGANIZATION,
      DeleteOrganizationModalProps
    >
  | GenericModalInfo<
      ModalType.MANAGE_ORGANIZATION_ADMIN,
      ManageOrganizationAdminModalProps
    >
  | GenericModalInfo<
      ModalType.MANAGE_ORGANIZATION_WORKSPACE,
      ManageOrganizationWorkspaceModalProps
    >
  | GenericModalInfo<
      ModalType.DELETE_ORGANIZATION_ADMIN,
      DeleteOrganizationAdminModalProps
    >
  | GenericModalInfo<
      ModalType.DELETE_WORKSPACE_MEMBER_ALERT,
      DeleteWorkspaceMemberAlertModalProps
    >
  | GenericModalInfo<ModalType.RECONFIRM, ReconfirmModalProps>
  | GenericModalInfo<ModalType.BULK_UPDATE_HISTORY, {}>
  | GenericModalInfo<ModalType.SET_DOWNLOAD_DATE, SetDownloadDateModalProps>
  | GenericModalInfo<
      ModalType.CONFIG_AUTOMATED_RENEW_NOTIFY_MAIL,
      ConfigAutomatedRenewNotifyMailModalProps
    >;

export type ModalState = {
  readonly status: ModalStateStatus.INIT;
  readonly openedModalList: ModalInfo[];
};

export type ModalAction =
  | ({
      readonly kind: ModalActionKind.TRY_OPEN;
    } & ModalInfo)
  | {
      readonly kind: ModalActionKind.TRY_CLOSE;
    }
  | {
      readonly kind: ModalActionKind.TRY_CLOSE_ALL;
    };

const smid = "MODAL_STATE_MACHINE3";
export type ModalStateMachineType = StateMachine3<
  ModalStateStatus,
  ModalState,
  ModalActionKind,
  ModalAction,
  ModalError
>;

export const modalStateMachine: ModalStateMachineType = new StateMachine3<
  ModalStateStatus,
  ModalState,
  ModalActionKind,
  ModalAction,
  ModalError
>(smid, { status: ModalStateStatus.INIT, openedModalList: [] }, [
  transition(
    ModalStateStatus.INIT,
    ModalStateStatus.INIT,
    ModalActionKind.TRY_OPEN
  ),
  transition(
    ModalStateStatus.INIT,
    ModalStateStatus.INIT,
    ModalActionKind.TRY_CLOSE
  ),
  transition(
    ModalStateStatus.INIT,
    ModalStateStatus.INIT,
    ModalActionKind.TRY_CLOSE_ALL
  ),
]);

export type DispatchModalAction = Dispatch<
  StateMachineAction<
    ModalStateStatus,
    ModalState,
    ModalActionKind,
    ModalAction,
    ModalError
  >
>;

export default mkReducer<
  ModalStateStatus,
  ModalState,
  ModalActionKind,
  ModalAction,
  ModalError
>(modalStateMachine);

export const doModalAction = (
  dispatch: DispatchModalAction,
  nextAction: ModalAction,
  onResolve: () => void = () => {},
  onReject: (err: ModalError | InternalError) => void = () => {}
) => {
  dispatch(modalStateMachine.newTryAction(nextAction, onResolve, onReject));
};

export const doModalActionAsync = (
  dispatch: DispatchModalAction,
  nextAction: ModalAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(modalStateMachine.newTryAction(nextAction, resolve, reject));
  });
};

export const resetModal = (dispatch: DispatchModalAction) => {
  dispatch(modalStateMachine.newResetAction());
};
